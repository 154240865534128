<template>
  <LayoutLeagues
    id="ligas-section"
    :is-loading="isLoading"
    v-bind="layoutLeagueProperties"
    @click="handlerEveryClick"
  >
    <HeaderLeagues
      :influencers="influencers"
      :filter-fields="filterFields"
      :default-filters="filters"
      :is-influencer-page="isInfluencerPage"
      @change-filters="handleFiltersChange"
    >
      <BannerInfluencer
        v-if="isInfluencerPage"
        slot="influencer-banner"
        :influencer="influencerPage"
      ></BannerInfluencer>
    </HeaderLeagues>
    <b-loading :active.sync="isLoading"></b-loading>
    <div v-if="!isLoading" class="league-container">
      <div class="category-title-content">
        <b-button
          type="is-pigeon"
          icon-pack="fas"
          icon-right="arrow-left"
          @click="handleBackLeagues"
        ></b-button>
        <h2
          class="title"
          :class="{ 'is-size-3': $mq !== 'sm', 'is-size-6': $mq === 'sm' }"
        >
          {{ title }}
        </h2>
      </div>

      <b-loading :active.sync="isLoadingLeagues"></b-loading>
      <div v-if="!isLoadingLeagues">
        <LeaguesManager
          :user-leagues="userLeagues"
          :loading="isLoadingLeagues"
          :leagues="leaguesVerified"
          :is-user-leagues="isUserLeagues"
          is-fluid
          @login="doLogin"
        ></LeaguesManager>
        <div
          v-if="!limitPagination && !showEmptySection"
          class="load-more-cards has-text-centered"
          @click="loadMoreLeagues"
        >
          <div class="load-more-cards-content">
            <b-loading
              :active.sync="isLoadingPagination"
              :is-full-page="false"
            ></b-loading>
            <div v-if="!isLoadingPagination" class="text">
              <b-icon pakc="fas" icon="plus"></b-icon>
              Carregar mais ligas
            </div>
          </div>
        </div>
        <div v-if="showEmptySection" class="section has-text-centered">
          <div class="mg-not-found">
            <b-icon
              icon="surprise"
              pack="far"
              size="is-large"
              type="is-pigeon"
            ></b-icon>
            <b-icon
              icon="question"
              pack="fas"
              size="is-large"
              type="is-pigeon"
            ></b-icon>
          </div>
          <h4
            class="has-text-pigeon"
            :class="{ 'is-size-4': $mq !== 'sm', 'is-size-6': $mq === 'sm' }"
          >
            Nenhuma Liga foi encontrada
          </h4>
          <p class="has-text-pigeon">Tente novamente com outros filtros!</p>
        </div>
      </div>
    </div>
    <NofityAway ref="notify"></NofityAway>
    <ShoppingCart></ShoppingCart>
  </LayoutLeagues>
</template>

<script>
import LigasService from "@/apps/Ligas/services/api";
import LeaguesMixin from "@/apps/Ligas/mixins/leagues";
import CategoriesMixin from "@/apps/Ligas/mixins/categories";
import LeaguesManager from "@/apps/Ligas/components/LeaguesManager.vue";
import BannerInfluencer from "@/apps/Ligas/components/BannerInfluencer.vue";

export default {
  name: "Category",
  mixins: [LeaguesMixin, CategoriesMixin],
  components: {
    LeaguesManager,
    BannerInfluencer,
  },
  data() {
    return {
      isLoadingPagination: false,
      limitPagination: false,
      leagues: [],
      category: {},
    };
  },
  async created() {
    this.isLoading = true;

    if (!this.isPublic) {
      await this.getUserData();
      this.loadUserLeagues();
    }

    this.getMercado();
    this.modifyDefaultFilters();

    let promiseInitialLoad = [this.loadFiltersFields(), this.loadInfluencers()];

    if (this.isInfluencerPage) {
      this.handleWhenIsInfluencerPage();
    }

    Promise.all(promiseInitialLoad).then(
      ([filterFieldsResponse, influencersResponse]) => {
        this.setFilterFields(filterFieldsResponse.data.data);
        this.setInfluencers(influencersResponse.data.data);

        this.isLoading = false;
        this.handleCategory();
      }
    );
  },
  computed: {
    categorySlug() {
      return this.$route.params.slug;
    },
    showEmptySection() {
      return this.leagues.length === 0;
    },
    isUserLeagues() {
      return this.categorySlug === "minhas-ligas";
    },
    title() {
      return this.category.descricao || "";
    },
    leaguesVerified() {
      if (this.isInfluencerPage)
        return this.leagues.filter(
          (room) => room.id_influencer === this.influencerPage.id
        );
      return this.leagues;
    },
  },
  methods: {
    modifyDefaultFilters() {
      this.filters.limit = 12;
      if (this.isUserLeagues) {
        this.filters.status = [
          "pre_venda",
          "aberta",
          "em_andamento",
          "encerrada",
        ];
      } else {
        this.filters.status = ["pre_venda", "aberta"];
      }
    },
    handleFiltersChange(filters) {
      this.filters = { ...filters };
      this.filters.page = 1;
      this.limitPagination = false;

      this.loadUserLeagues();
      this.loadLeagues();
    },
    handlerEveryClick() {
      this.$refs.notify.handlerEveryClick();
    },
    loadCategoryBySlug() {
      return LigasService.getCategoryBySlug(this.categorySlug);
    },
    setCategory(category) {
      this.category = category;
    },
    handleCategory() {
      if (this.isUserLeagues) {
        this.setCategory({
          descricao: "Minhas Ligas",
          id: null,
          ordem: null,
          slug: "minhas-ligas",
        });
        this.loadLeagues();
      } else {
        this.loadCategoryBySlug().then((response) => {
          this.setCategory(response.data.data);
          this.loadLeagues();
        });
      }
    },
    handleLoadLeagues() {
      if (this.isUserLeagues) {
        return this.getLeaguesByUser();
      }
      return this.loadLeaguesByCategory(this.category.id);
    },
    loadLeagues() {
      this.handleLoadLeagues()
        .then((response) => {
          const leaguesResponse = response.data.data;
          this.leagues = this.getLeaguesFormatted(leaguesResponse);
        })
        .finally(() => {
          this.isLoadingLeagues = false;
        });
    },
    loadMoreLeagues() {
      this.isLoadingPagination = true;
      this.filters.page += 1;
      this.handleLoadLeagues()
        .then((response) => {
          const leaguesResponse = response.data.data;
          if (leaguesResponse.length === 0) {
            this.limitPagination = true;
          } else {
            const leaguesFormatted = this.getLeaguesFormatted(leaguesResponse);
            this.leagues.push(...leaguesFormatted);
          }
        })
        .finally(() => {
          this.isLoadingLeagues = false;
          this.isLoadingPagination = false;
        });
    },
    handleBackLeagues() {
      let route = `/ligas`;
      if (this.isInfluencerPage) {
        route = `/ligas/${this.influencerPage.slug}`;
      }
      this.$router.push(route);
    },
    doLogin() {
      this.setPublic(false);
      Promise.all([
        this.getUserData(),
        this.getLigasTimesCartola(this.userId),
        this.getWallet(this.userId),
        this.loadLeagues(),
      ]).then(() => {
        this.loadUserLeagues();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.league-container {
  padding-bottom: 5rem;
}

.category-title-content {
  display: flex;
  align-items: center;
  margin-bottom: 2em;

  h2 {
    margin-left: 0.5em;
    margin-top: 0;
  }
}

.load-more-cards {
  width: 100%;
  margin: 2rem 0 3rem 0;

  .load-more-cards-content {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 70px;
    width: 100%;
    padding: 1rem;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #3c3f55;
    background-color: #292c3f;

    .text {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        margin-right: 0.5em;
      }
    }

    &:hover {
      transition: all 0.2s ease-in-out;
      background-color: #353950;
    }
  }
}
</style>
